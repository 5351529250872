import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { useBNBPrice } from 'hooks/useBNBPrice'
import { useEffect, useRef, useState } from 'react'
import { pubSubInstance } from './index'

export interface LogData {
  _id: string
  address: string
  blockHash: string
  blockNumber: string
  data: string
  id: string
  logIndex: string
  removed: boolean
  topics: string[]
  transactionHash: string
  transactionIndex: string
  updatedAt: string
}

const abi = [
  'event Swap(address indexed sender,uint amount0In,uint amount1In,uint amount0Out,uint amount1Out,address indexed to)',
]
const iface = new ethers.utils.Interface(abi)

type TransactionLogProps = {
  data: LogData[]
  bnbPosition: number
  // bnbPrice: string;
  decimals: number
  latestBlock: number
  lpAddress: string
  name: string
}

function transformLogData(item: LogData, decimals: number, bnbPosition: number, bnbPrice: string) {
  if (bnbPosition === 0) {
    const parsedData = iface.parseLog(item)
    const orderSide = new BigNumber(parsedData?.args.amount0In.toString()).gt(0) ? 'BUY' : 'SELL'
    const tokenAmount =
      orderSide === 'BUY'
        ? new BigNumber(parsedData?.args.amount1Out.toString()).div(`1e${decimals}`)
        : new BigNumber(parsedData?.args.amount1In.toString()).div(`1e${decimals}`)
    const totalAmount =
      orderSide === 'BUY'
        ? new BigNumber(parsedData?.args.amount0In.toString()).div(1e18)
        : new BigNumber(parsedData?.args.amount0Out.toString()).div(1e18)

    const price =
      orderSide === 'SELL'
        ? new BigNumber(parsedData?.args.amount0Out.toString())
            .div(parsedData?.args.amount1In.toString())
            .div(new BigNumber(1e18).div(`1e${decimals}`))
            .times(bnbPrice)
        : new BigNumber(parsedData?.args.amount0In.toString())
            .div(parsedData?.args.amount1Out.toString())
            .div(new BigNumber(1e18).div(`1e${decimals}`))
            .times(bnbPrice)
    return {
      side: orderSide,
      token: tokenAmount.toFormat(8),
      total: totalAmount.toFixed(8),
      totalUsd: totalAmount.times(bnbPrice).toFormat(2),
      price: price.toFixed(12),
      date: new Date(item.updatedAt).toLocaleTimeString(),
      time: item.updatedAt,
      txHash: item.transactionHash,
    }
  } else {
    const parsedData = iface.parseLog(item)
    const orderSide = new BigNumber(parsedData?.args.amount1In.toString()).gt(0) ? 'BUY' : 'SELL'
    const tokenAmount =
      orderSide === 'BUY'
        ? new BigNumber(parsedData?.args.amount0Out.toString()).div(`1e${decimals}`)
        : new BigNumber(parsedData?.args.amount0In.toString()).div(`1e${decimals}`)
    const totalAmount =
      orderSide === 'BUY'
        ? new BigNumber(parsedData?.args.amount1In.toString()).div(1e18)
        : new BigNumber(parsedData?.args.amount1Out.toString()).div(1e18)
    const price =
      orderSide === 'SELL'
        ? new BigNumber(parsedData?.args.amount1Out.toString())
            .div(parsedData?.args.amount0In.toString())
            .div(new BigNumber(1e18).div(`1e${decimals}`))
            .times(bnbPrice)
        : new BigNumber(parsedData?.args.amount1In.toString())
            .div(parsedData?.args.amount0Out.toString())
            .div(new BigNumber(1e18).div(`1e${decimals}`))
            .times(bnbPrice)
    return {
      side: orderSide,
      token: tokenAmount.toFormat(8),
      total: totalAmount.toFixed(8),
      totalUsd: totalAmount.times(bnbPrice).toFormat(2),
      price: price.toFixed(8),
      date: new Date(item.updatedAt).toLocaleTimeString(),
      time: item.updatedAt,
      txHash: item.transactionHash,
    }
  }
}

function TransactionLog({
  data,
  bnbPosition,
  // bnbPrice,
  decimals,
  latestBlock,
  lpAddress,
  name,
}: TransactionLogProps) {
  const bnbPrice = useBNBPrice()
  let cacheBlockNumber = useRef(latestBlock)

  const [historyData, setHistoryData] = useState([])
  const tableData = data
    .concat(historyData)
    .map((item) => transformLogData(item, decimals, bnbPosition, bnbPrice.toString()))

  const fetchData = async (lpAddress: string) => {
    const swapLog = await fetch(`https://data-api.poocoin.app/swap-logs-bsc?`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        jsonrpc: '2.0',
        id: Number(`${Math.floor(Math.random() * 100000)}` + `${new Date().getTime()}`),
        // id: 29159534455518112,
        method: 'eth_getLogs',
        params: [
          {
            fromBlock: '0x' + cacheBlockNumber.current.toString(16),
            toBlock: 'latest',
            topics: [
              '0xd78ad95fa46c994b6551d0da85fc275fe613ce37657fb8d5e3d130840159d822',
              '0x19b47279256b2a23a1665c810c8d55a1758940ee09377d4f8d26497a3577dc83',
            ],
            address: lpAddress,
          },
        ],
      }),
    }).then((res) => res.json())

    if (swapLog.result.length > 0) {
      // @ts-ignore
      setHistoryData(() => [...historyData, ...swapLog.result])
      if (bnbPrice.gt(0)) {
        pubSubInstance.publish(
          swapLog.result.map((item: any) => transformLogData(item, decimals, bnbPosition, bnbPrice.toString())),
        )
      }
      cacheBlockNumber.current = parseInt(swapLog.result[swapLog.result.length - 1].blockNumber, 16)
    }
  }

  useEffect(() => {
    setHistoryData([])

    const interval = setInterval(() => {
      if (lpAddress) {
        fetchData(lpAddress)
      }
    }, 10_000)

    return () => clearInterval(interval)
  }, [lpAddress])

  return (
    <div className="h-[500px] rounded-md border" style={{ maxHeight: 400, overflowY: 'auto', marginTop: 24 }}>
      <table style={{ width: '100%', borderRadius: 8 }}>
        {/* <TableCaption>A list of your recent invoices.</TableCaption> */}
        <thead>
          <tr style={{ background: 'white', border: '1px solid #e2e8f0' }}>
            <td style={{padding: '12px 12px'}} className="text-right">Tokens</td>
            <td style={{padding: '12px 12px'}} className="text-right">Total</td>
            <td style={{padding: '12px 12px'}} className="text-right">Price</td>
            <td style={{padding: '12px 12px'}} className="text-right">Date</td>
            <td style={{padding: '12px 12px'}} className="text-left">Tx</td>
          </tr>
        </thead>
        <tbody>
          {tableData.reverse().map((tx) => (
            <tr
              // key={tx.txHash}
              className={tx.side === 'SELL' ? `text-red-500` : `text-green-500`}
              style={{
                background: 'white',
                color: tx.side === 'SELL' ? `red` : `green`,
                border: '1px solid #e2e8f0',
              }}
            >
              <td style={{padding: '12px 12px'}} className="text-right">
                <div className="flex flex-col">
                  <p>{tx.token}</p>
                  <p className="text-xs" style={{ fontSize: 12 }}>{name}</p>
                </div>
              </td>
              <td style={{padding: '12px 12px'}} className="text-right">
                <div className="flex flex-col">
                  <p>${tx.totalUsd}</p>
                  <p className="text-xs" style={{ fontSize: 12 }}>{tx.total} BNB</p>
                </div>
              </td>
              <td style={{padding: '12px 12px'}} className="text-right">
                <div className="flex flex-col">
                  <p>${tx.price}</p>
                  <p className="text-xs" style={{ fontSize: 12 }}>Pc v2</p>
                </div>
              </td>
              <td style={{padding: '12px 12px'}} className="text-right">
                <div className="flex flex-col">
                  <p>{tx.date.split(' ')[0]}</p>
                  <p className="text-xs" style={{ fontSize: 12 }}>{tx.date.split(' ')[1]}</p>
                </div>
              </td>
              <td style={{padding: '12px 12px'}} className="text-left">
                <a href={`https://bscscan.com/tx/${tx.txHash}`} target="_blank" className="text-blue-500" style={{color: 'blue'}}>
                  <p>{tx.txHash.slice(0, 6)}</p>
                  <p>Track</p>
                </a>
              </td>
            </tr>
          ))}
        </tbody>
        {/* <TableFooter>
        <TableRow>
          <TableCell colSpan={3}>Total</TableCell>
          <TableCell className="text-right">$2,500.00</TableCell>
        </TableRow>
      </TableFooter> */}
      </table>
    </div>
  )
}

export default TransactionLog
